import React from "react";
import { Outlet, Route, useNavigate, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import ResourceDisplay from "../../../components/resource-display";
import ResourceService from "../../../service/resource";
import OutputResource from "../../../dtos/resource";
import { useLoaderData } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import * as PageIndex from "./page-index";
import * as PageReviews from "./page-reviews";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { OutputTransmedCounty } from "../../../dtos/transmed/county";
import TransmedService from "../../../service/transmed";
import PinMap, { MapPin, MapPinGroup } from "../../../components/pin-map";
import { IMAGE_HOST } from "../../../api/config";

export const path = "transmed/";

const resourceKeys = {
  header: "transmed-page-header",
  leftline: "transmed-page-leftline",
  locomotiva: "transmed-page-locomotiva",
  card: "transmed-page-card",
  cardVertical: "transmed-page-cardResize",
  subtitle: "transmed-page-cardSubtitle",
  title: "transmed-page-cardTitle",
  romaniaMap: "opportunities-exchanges-romania-map",
};

type LoaderType = [{ [key: string]: OutputResource }, OutputTransmedCounty[]];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const counties = new TransmedService().read.counties();
  return [await resources, await counties];
}
export function Component() {
  const [resources, counties] = useLoaderData() as LoaderType;
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <div className="page-oportunitati-transmed">
      <ResourceDisplay
        resource={resources[resourceKeys.header]}
        className="header"
      />
      <div className="mid-page">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="vagon-swiper"
        >
          {[1, 2, 3].map(() => (
            <SwiperSlide>
              <div className="connection-line" />
              <div className="vagon-container content-container">
                <div className="vagon">
                  <ResourceDisplay
                    resource={
                      width > 650
                        ? resources[resourceKeys.card]
                        : resources[resourceKeys.cardVertical]
                    }
                    className="card"
                  />
                </div>
                <div className="wheels">
                  <div className="left-wheel" />
                  <div className="right-wheel" />
                </div>
              </div>
              <div className="connection-line" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <section className="reviews">
        <PinMap
          map={IMAGE_HOST + resources[resourceKeys.romaniaMap].value}
          pins={counties.map(
            (county) =>
              new MapPinGroup(
                county.title,
                [
                  new MapPin("", 3828628, () =>
                    navigate(`${county.name}/`, {
                      preventScrollReset: true,
                    })
                  ),
                ],
                county.location_x,
                county.location_y
              )
          )}
        />
        <Outlet />
      </section>
    </div>
  );
}
Component.displayName = "page-oportunitati-transmed";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-transmed-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageReviews} />,
];
