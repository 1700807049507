import React from "react";
import { useLoaderData, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error";
import ResourceService from "../../../../service/resource";
import OutputResource from "../../../../dtos/resource";
import ResourceDisplay from "../../../../components/resource-display";

const resourceKeys = {
  headerWide: "opportunities-exchanges-world-map",
};

type LoaderType = [{ [key: string]: OutputResource }];

export async function loader(): Promise<LoaderType> {
  return [await new ResourceService().read.byIds(Object.values(resourceKeys))];
}

export function Component() {
  const [resources] = useLoaderData() as LoaderType;
  return (
    <div id="default">
      {/* <div className="overlay" /> */}
      <div className="map">
        <ResourceDisplay resource={resources[resourceKeys.headerWide]} />
      </div>
    </div>
  );
}
Component.displayName = "page-oportunitati-exchanges-default";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-default-error";
