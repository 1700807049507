import axios, { toFormData } from "axios";
import { SERVER_HOST } from "./config";
import { InputEntity, RequestEntity } from "../dtos/index";

export default class Api {
  public static toFormData(
    obj: any,
    formData: FormData = new FormData(),
    parentKey: string = ""
  ): FormData {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        const value = obj[key];

        if (value instanceof Date) {
          formData.append(fullKey, value.toISOString());
        } else if (value instanceof File || value instanceof Blob) {
          formData.append(fullKey, value);
        } else if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof File || value instanceof Blob)
        ) {
          Api.toFormData(value, formData, fullKey);
        } else {
          formData.append(fullKey, value);
        }
      }
    }

    return formData;
  }

  constructor(
    protected readonly uri: string,
    protected readonly host: string = SERVER_HOST
  ) {}

  public get endpoint(): string {
    return this.host + this.uri;
  }

  public async get(
    params: RequestEntity | null | undefined = null
  ): Promise<any> {
    if (params === undefined || params === null)
      return axios.get(this.endpoint);

    return (
      await axios.get(this.endpoint, {
        params: Object.fromEntries(
          Object.entries(params).map(([key, value]) => [
            key,
            JSON.stringify(value),
          ])
        ),
      })
    ).data;
  }

  public post(data: InputEntity | null | undefined = null): Promise<any> {
    if (data === undefined || data === null) return axios.post(this.endpoint);

    return axios({
      method: "post",
      url: this.endpoint,
      data: toFormData(data),
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
