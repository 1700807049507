import "./styles.css";
import React from "react";

const neuronBlue = require("../../images/geometric-neuron-blue.svg");
const neuronWhite = require("../../images/geometric-neuron-white.svg");

type propsType = {
  title?: string;
  side?: "left" | "right";
  onClick?: (e: any) => void;
  className?: string;
};

function ButtonPrimarySide({
  title,
  side = "left",
  onClick,
  className = "",
  ...others
}: propsType) {
  return (
    <button
      className={
        "fill-button button-primary-side " +
        side +
        (className ? " " + className : "")
      }
      onClick={onClick}
      {...others}
    >
      {title}
      <div className="inside line" />
      <div className="outside line" />
      <img src={neuronBlue} alt="" className="blue" />
      <img src={neuronWhite} alt="" className="white" />
    </button>
  );
}

export default ButtonPrimarySide;
