import {
  LoaderFunctionArgs,
  useLoaderData,
  useRouteError,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../../../generic-error";

import { paramNames } from "../index";
import {
  OutputExchangesOutReview,
  RequestOutReviewType,
} from "../../../../../../dtos/exchanges_out";
import ExchangesOutService from "../../../../../../service/exchanges_out";
import ReviewDisplay, {
  ReviewDisplayReview,
} from "../../../../../../components/review-display";
import ResourceDisplay from "../../../../../../components/resource-display";
import ResourceService from "../../../../../../service/resource";
import OutputResource from "../../../../../../dtos/resource";
const countryParamName = "country";
export const path = `:${countryParamName}/`;

const resourceKeys = {
  aboutLinie: "about-nonews-liniealbastra",
  aboutPeople: "about-nonews-people",
};
type LoaderType = [
  OutputExchangesOutReview[],
  { [key: string]: OutputResource }
];

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderType> {
  const reviews = new ExchangesOutService().read.byCountry(
    params[countryParamName]!,
    RequestOutReviewType[
      params[paramNames.type]! as keyof typeof RequestOutReviewType
    ]
  );
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  return [await reviews, await resources];
}

export function Component() {
  const [reviews, resources] = useLoaderData() as LoaderType;

  return (
    <section className="list content-container">
      {reviews.length === 0 ? (
        <div className="content-container">
          <div className="no-reviews">
            <div className="big-container">
              <h2 className="text-container">No reviews available</h2>
              <div className="line-container">
                <ResourceDisplay
                  resource={resources[resourceKeys.aboutLinie]}
                />
              </div>
            </div>
            <div className="image-container">
              <ResourceDisplay resource={resources[resourceKeys.aboutPeople]} />
            </div>
          </div>
        </div>
      ) : (
        <>
          {reviews.map((review) => (
            <ReviewDisplay
              review={
                new ReviewDisplayReview(
                  review.name,
                  review.date.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                  }),
                  review.title,
                  review.pros,
                  review.cons,
                  review.city
                )
              }
            />
          ))}
        </>
      )}
    </section>
  );
}
Component.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews-error";
