import OutputEntity, { DtoConversionError, RequestEntity } from "./index";

export class OutputExchangesCounty extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly scope: boolean,
    public readonly score: boolean,
    public readonly trip: boolean,
    public readonly location_x: number,
    public readonly location_y: number
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesCounty {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const scope = input.scope ?? undefined;
    const score = input.score ?? undefined;
    const trip = input.score ?? undefined;
    const location_x = input.location_x ?? undefined;
    const location_y = input.location_y ?? undefined;

    if (typeof id !== "number")
      throw new OutputExchangesCountyConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesCountyConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputExchangesCountyConversionError("title", input);
    if (typeof scope !== "boolean")
      throw new OutputExchangesCountyConversionError("scope", input);
    if (typeof score !== "boolean")
      throw new OutputExchangesCountyConversionError("scope", input);
    if (typeof trip !== "boolean")
      throw new OutputExchangesCountyConversionError("trip", input);
    if (typeof location_x !== "number")
      throw new OutputExchangesCountyConversionError("location_x", input);
    if (typeof location_y !== "number")
      throw new OutputExchangesCountyConversionError("location_y", input);

    return OutputExchangesCountyConversionError.propagate(
      OutputExchangesCountyConversionError.dtoName,
      () =>
        new OutputExchangesCounty(
          id,
          name,
          title,
          scope,
          score,
          trip,
          location_x,
          location_y
        )
    );
  }
}

class OutputExchangesCountyConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesCounty";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesCountyConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class OutputExchangesInReview extends OutputEntity {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly title: string,
    public readonly pros: string,
    public readonly cons: string | undefined,
    public readonly date: Date
  ) {
    super(id);
  }

  static convert(input: any): OutputExchangesInReview {
    const id = input.id ?? undefined;
    const name = input.name ?? undefined;
    const title = input.title ?? undefined;
    const pros = input.pros ?? undefined;
    const cons = input.cons ?? undefined;
    const date = input.date ?? undefined;

    console.log(cons);

    if (typeof id !== "number")
      throw new OutputExchangesInReviewConversionError("id", input);
    if (typeof name !== "string")
      throw new OutputExchangesInReviewConversionError("name", input);
    if (typeof title !== "string")
      throw new OutputExchangesInReviewConversionError("title", input);
    if (typeof pros !== "string")
      throw new OutputExchangesInReviewConversionError("pros", input);
    if (typeof cons !== "string" && cons !== undefined)
      throw new OutputExchangesInReviewConversionError("cons", input);
    if (typeof date !== "string")
      throw new OutputExchangesInReviewConversionError("date", input);

    return OutputExchangesInReviewConversionError.propagate(
      OutputExchangesInReviewConversionError.dtoName,
      () =>
        new OutputExchangesInReview(id, name, title, pros, cons, new Date(date))
    );
  }
}

class OutputExchangesInReviewConversionError extends DtoConversionError {
  public static dtoName: string = "OutputExchangesInReview";
  constructor(propertyName: string, rawData: any, hint?: string) {
    super(
      OutputExchangesInReviewConversionError.dtoName,
      propertyName,
      rawData,
      hint
    );
  }
}

export class RequestCounties extends RequestEntity {
  public readonly counties = null;
}

export enum RequestInReviewType {
  "scope" = "scope",
  "score" = "score",
  "trip" = "trip",
}

export class RequestInReviewsByCounty extends RequestEntity {
  public readonly reviews = null;
  constructor(
    public readonly county: string,
    public readonly type: RequestInReviewType
  ) {
    super();
  }
}
