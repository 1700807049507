import React from "react";
import { Outlet, Route, useLoaderData, useRouteError } from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import ResourceService from "../../../service/resource";
import OutputResource from "../../../dtos/resource";
import ResourceDisplay from "../../../components/resource-display";
import * as PageIndex from "./default/index";
import * as PageIn from "./incomings/index";
import * as PageOut from "./outgoings/index";
import ButtonExchanges, {
  ExchangesType,
} from "../../../components/button-exchanges";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export const path = "exchanges/";

const resourceKeys = {
  headerWide: "opportunities-exchanges-header-wide",
  planeLeft: "opportunities-exchanges-plane-left",
  lane: "opportunities-lane",
};

type LoaderType = [{ [key: string]: OutputResource }];

export async function loader(): Promise<LoaderType> {
  return [await new ResourceService().read.byIds(Object.values(resourceKeys))];
}

export function Component() {
  const [resources] = useLoaderData() as LoaderType;

  return (
    <div id="page-oportunitati-exchanges">
      <header>
        <ResourceDisplay resource={resources[resourceKeys.headerWide]} />
      </header>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="airplane-swiper"
      >
        {[1, 2, 3].map(() => (
          <SwiperSlide>
            <div className="mid-container">
              <div className="upper-container">
                <div className="air left">
                  <ResourceDisplay
                    resource={resources[resourceKeys.lane]}
                    className="lane"
                  />
                  <div className="airplane">
                    <ResourceDisplay
                      resource={resources[resourceKeys.planeLeft]}
                    />
                  </div>
                </div>
                <div className="title-container">
                  <h3 className="title">Noua experienta este aici</h3>
                  <div className="button">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </div>
                <div className="air">
                  <ResourceDisplay
                    resource={resources[resourceKeys.lane]}
                    className="lane"
                  />
                </div>
              </div>
              <div className="lower-container">
                <div className="subtitle">
                  Principiul oportunității este un principiu al dreptului
                  olandez care afirmă că o contravenție sau infracțiune va fi
                  pedepsită doar dacă pedepsirea ei este considerată oportună.
                  Aceasta înseamnă că procurorii au puterea de decizie arbitrară
                  de a anula urmărirea penală a unei contravenții sau
                  infracțiuni. Încetarea urmăririi penale se numește sepot.
                </div>
                <div className="line"></div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <section className="content-container">
        <div className="account">Account</div>
      </section>
      <section className="reviews">
        <div className="buttons">
          <ButtonExchanges type={ExchangesType.out} />
          <ButtonExchanges type={ExchangesType.in} />
        </div>
        <Outlet />
      </section>
    </div>
  );
}
Component.displayName = "page-oportunitati-exchanges";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-exchanges-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageIn} />,
  <Route {...PageOut} />,
];
